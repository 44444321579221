import axios from "axios";
import { useState } from 'react';

const useAxios = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchData = async (params) => {
    setLoading(true)
    try {
      const result = await axios.request({ ...params });
      setResponse(result.data);
    } catch (error) {     
      setError(error.response?.data.message);
    } finally {
      setLoading(false);
    }
  };
  return { response, error, loading, fetchData };
}

export default useAxios