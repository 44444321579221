import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import '../node_modules/primeflex/primeflex.css';
import '../node_modules/primereact/resources/themes/lara-light-indigo/theme.css';
import '../node_modules/primeicons/primeicons.css';
import '../node_modules/primereact/resources/primereact.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  //<React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  //</React.StrictMode>
);

reportWebVitals();
