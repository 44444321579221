import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import BtsDown from "./pages/alarms/BtsDown";

const App = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<BtsDown />}></Route>
      </Routes >
    </>
  );
};

export default App;